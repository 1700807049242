'use client';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';

const productImages = [
  "url('/images/products/shopping2.webp')",
  "url('/images/products/shopping4.webp')",
  "url('/images/products/shopping1.webp')",
  "url('/images/products/shopping5.webp')",
  "url('/images/products/shopping6.webp')",
];

const HeroSection = () => {
  const title = '🛍️ Des Produits de Qualité, Une Expérience Unique';
  const subtitle = 'Découvrez, achetez en toute confiance et profitez d’une livraison rapide !';

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % productImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className="hero-section relative flex flex-col items-center justify-center text-center py-20 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: productImages[currentImageIndex], transition: 'background-image 1s ease-in-out' }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content Wrapper */}
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.75 }}
          className="bg-black bg-opacity-50 p-6 rounded-2xl shadow-lg backdrop-blur-md inline-block max-w-2xl"
        >
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className="text-3xl md:text-5xl font-bold text-white mb-4 drop-shadow-lg"
          >
            {title}
          </motion.h1>
          <motion.h3
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className="text-white font-semibold text-lg md:text-2xl drop-shadow-md mb-6"
          >
            {subtitle}
          </motion.h3>
          
          {/* Button */}
          <div className="flex justify-center">
            <Link href={'/products-listing?productCategory=&location='}>
              <Button
                size="large"
                endIcon={<FontAwesomeIcon icon={faMagnifyingGlassPlus} />}
                className="bg-gray-950 text-white my-4"
              >
                Découvrez Plus
              </Button>
            </Link>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
