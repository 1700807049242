'use client';
import { faCar, faCartShopping, faHandshake, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@heroui/react";
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { store } from '@/redux/store';
import UseRegisterModal from '@/app/hooks/useRegisterModal';
import Image from 'next/image';

const MainBannerVendre: React.FC = () => {
    const { isOpen: isAddModalOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [user, setUser] = useState<any | null>(null);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const registerModal = UseRegisterModal();

    useEffect(() => {
        const userData = store.getState()?.authSlice?.user ?? {};
        setUser(userData);
        setAuthenticated(!!userData?.email);
    }, []);

    const handleAddClick = () => {
        if (isAuthenticated) {
            onAddOpen();
            setShowRegister(false);
        } else {
            setShowRegister(true);
            registerModal.onOpen();
        }
    };

    const closeRegister = () => {
        setShowRegister(false);
    };

    return (
        <section className="relative text-white bg-white py-16"
            style={{ backgroundImage: "url('/images/backgroundFame2.png')" }}>
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                    {/* Left Image Column */}
                    <div className="w-full md:w-1/4 flex items-center justify-center hidden md:flex">
                        <div className="relative w-full">
                            <Image
                                src="/images/leftIcon.png"
                                alt="Selling items"
                                width={300}
                                height={300}
                                className="w-auto h-auto object-contain"
                            />
                            {/* <div className="absolute inset-0 bg-black bg-opacity-20"></div> */}
                        </div>
                    </div>

                    {/* Center Content Column */}
                    <div className="w-full md:w-2/4 text-center px-6">
                        <div className="max-w-xl mx-auto">
                            <h2 className="text-3xl font-bold mb-6">
                                C'est le moment parfait pour vendre vos articles !
                            </h2>
                            <p className="text-lg mb-8">
                                Que ce soit des objets neufs ou d'occasion, mettez-les en vente dès aujourd'hui et profitez de l'opportunité.
                            </p>
                            <button
                                onClick={handleAddClick}
                                className="inline-block bg-black text-white font-semibold py-3 px-6 rounded-md shadow-lg hover:bg-gray-800 transition-all"
                            >
                                <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
                                Commencez à vendre
                            </button>
                        </div>
                    </div>

                    {/* Right Image Column */}
                    <div className="w-full md:w-1/4 flex items-center justify-center">
                        <div className="relative w-full">
                            <Image
                                src="/images/rightIcon.png"
                                alt="Shopping experience"
                                width={500}
                                height={500}
                                className="w-auto h-auto object-contain"
                                priority
                            />
                            {/* <div className="absolute inset-0 bg-black bg-opacity-20"></div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Listing Modal */}
            <Modal isOpen={isAddModalOpen} placement="center" onClose={onAddClose}>
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        Ajouter une annonce
                    </ModalHeader>
                    <ModalBody>
                        <Link href="/create-products">
                            <Button size="lg" fullWidth className="mb-2">
                                <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
                                Ajouter Produit
                            </Button>
                        </Link>
                        {/* <Link href="/create-property">
                            <Button size="lg" fullWidth className="mb-2">
                                <FontAwesomeIcon icon={faHouse} className="mr-2" />
                                Ajouter Propriété
                            </Button>
                        </Link> */}
                        {/* <Link href="/create-vehicle">
                            <Button size="lg" fullWidth className="mb-2">
                                <FontAwesomeIcon icon={faCar} className="mr-2" />
                                Ajouter Véhicule
                            </Button>
                        </Link> */}
                        {/* <Link href="/create-service">
                            <Button size="lg" fullWidth className="mb-2">
                                <FontAwesomeIcon icon={faHandshake} className="mr-2" />
                                Ajouter Service
                            </Button>
                        </Link> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onAddClose}>
                            Fermer
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </section>
    );
};

export default MainBannerVendre;